import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
const toast = (message, type = 'info') => {
	if(type == 'success')success(message);
	else if(type == 'warning')warning(message);
	else if(type == 'error')error(message);
	else ElMessage(message);
}
const result = (message, rs = true) => {
	if(message.message) {
		rs = message.rs;
		message = message.message;
	}
	if(rs)success(message);
	else error(message);
}
const message = message => {
	toast(message);
}
const success = message => {
	ElMessage({
		type: 'success',
		message,
	});
}
const warning = message => {
	ElMessage({
		type: 'warning',
		message,
	});
}
const error = message => {
	ElMessage.error(message);
}
const alert = (message, callback, title) => {
	ElMessageBox.alert(message, title || '', {
		// if you want to disable its autofocus
		// autofocus: false,
		confirmButtonText: '确定',
		dangerouslyUseHTMLString: true,
		callback() {
			if(typeof callback == 'function')callback();
		},
	});
}
const confirm = (message, callback1, callback0, title) => {
	ElMessageBox.confirm(
		message,
		title || '确认提示',
		{
			type: 'warning',
			dangerouslyUseHTMLString: true,
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		}
	).then(() => {
		if(typeof callback1 == 'function')callback1();
	}).catch(() => {
		if(typeof callback0 == 'function')callback0();
	});
}
const prompt = (title = '请输入', def = '', callback1, callback0) => {
	ElMessageBox.prompt(
		title,
		{
			inputValue: def,
			dangerouslyUseHTMLString: true,
			inputPlaceholder: '请输入',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		}
	).then(({ value }) => {
		if(typeof callback1 == 'function')callback1(value);
	}).catch(() => {
		if(typeof callback0 == 'function')callback0();
	});
}
const loading = (text = '') => {
	ElLoading.service({ fullscreen: true, text })
}
const loaded = () => {
	ElLoading.service({ fullscreen: true }).close();
}
export default {
	toast, result, message, success, warning, error, alert, confirm, prompt, loading, loaded
}